<mat-sidenav-container class="layout-fill">

    <mat-sidenav #sidenav [opened]="isOpen" [fixedInViewport]="true" mode="over" [ngClass]="sidenavClass"
        position="start" [disableClose]="!isMobile">

        @if(isReady){
        <ddf-menu [sidenav]="sidenav"></ddf-menu>
        }

    </mat-sidenav>

    <mat-sidenav-content>
        @if(isReady){
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-white">

            <button mat-icon-button (click)="toggleSidenav()">
                <mat-icon>menu</mat-icon>
            </button>

            <a routerLink="/">
                <img src="assets/images/ddfinance-logo.png" alt="DDFinance" class="tw-max-h-[24px]" />
            </a>

        </div>

        <router-outlet></router-outlet>
        }


    </mat-sidenav-content>

</mat-sidenav-container>