<mat-sidenav-container class="layout-fill">

    <mat-sidenav #sidenav style="min-width: 320px; max-width: 90%;" [opened]="isOpen" [fixedInViewport]="true"
        [mode]="mode" [ngClass]="sidenavClass" [position]="'start'" [disableClose]="!isMobile">

        <ng-container *ngIf="isReady">
            <ddf-menu [sidenav]="sidenav" [isMobile]="isMobile" [isOpen]="isOpen"></ddf-menu>
        </ng-container>

    </mat-sidenav>

    <mat-sidenav-content>
        <div class=" ">
            <ng-container *ngIf="isReady">

                <header class="is-transparent hide-gt-sm">

                    <div class="ph-15">

                        <div class="logo layout-row layout-align-space-between-center">

                            <a routerLink="/" class="flex">
                                <img src="assets/ddfinance-logo.png" alt="DDFinance" style="max-height: 35px;" />
                            </a>

                            <button mat-icon-button (click)="sidenav.toggle()" *ngIf="isMobile">
                                <mat-icon [svgIcon]="isOpen ? 'close' : 'view-dashboard'" aria-label="Menu"></mat-icon>
                            </button>

                        </div>

                    </div>

                </header>

                <router-outlet></router-outlet>


            </ng-container>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>