import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuLink, UiService } from '../providers/ui.service';
import { BaseComponent } from '@core/abstracts/base.component';
import { MatSidenav } from '@angular/material/sidenav';
import { Identity, TokenService } from '@core/services/token.service';
import { AuthService } from '@core/services/auth.service';

@Component({
    selector: 'ddf-menu',
    templateUrl: './menu.html',
    inputs: ['sidenav', 'isMobile', 'isOpen'],
    standalone: false
})
export class MenuComponent extends BaseComponent
{
    public identity: Identity | undefined;

    public menuLinks: MenuLink[] = [];
    public sidenav: MatSidenav | undefined;
    public isMobile = false;
    public isOpen: boolean = true;
    public langs = [];
    public currentLang: string;

    constructor(
        public tokenService: TokenService,
        public authService: AuthService,
        public uiService: UiService,
        private router: Router,
    )
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        this.identity = this.tokenService.getIdentity();
        this.menuLinks = this.uiService.getMenuLinks()
            .filter(x => !x.title.includes('Profile'));
    }

    public selectMenu(ev: Event, route: string[]): void
    {
        if (!this.sidenav) return;
        this.sidenav.toggle(!this.isMobile);
    }

    public async signoutAsync(): Promise<void>
    {
        await this.router.navigateByUrl('/auth/signin');
        await this.authService.signoutAsync();

        this.uiService.removeFilterSettingsFromLocalStorage();
    }
}
