<section class="layout-column layout-align-center-center" style="min-height: 30rem; max-height: 70%;">
    <div class="container">
        <div style="display: flex">
            <div class="col s6 text-center">
                <h2 style="font-size: 152px!important; margin: 40px">404</h2>
            </div>
            <div class="col s6">
                <p style="font-size: 77px!important;    color: rgba(0,0,0,.3)!important;
        fill: rgba(0,0,0,.3)!important;padding:20px;">We couldn’t find this page.</p>
            </div>
        </div>

            <h2 style="font-size: 40px!important; margin: 10px">Here is a fun fact about us!</h2>
            <p style="font-size: 20px!important;    color: rgba(0,0,0,.3)!important;
        fill: rgba(0,0,0,.3)!important;padding:20px">We drive costs out of the value chain to enable profitable and sustainable insurance in very low-margin
                environments. Our approach is to develop sophisticated automation techniques driven by an understanding
                of how the markets within underserved society segments currently operate - as collectives bound through
                informal networks of trust and reputation.</p>

        <h4 style="font-size: 25px!important; margin: 10px">Still feeling lost?</h4>
        <p style="font-size: 20px!important;    color: rgba(0,0,0,.3)!important;
        fill: rgba(0,0,0,.3)!important;padding:10px"><strong> Reach Us at: <a href="mailto:support@ddfinance.com">support&#64;ddfinance.com</a></strong></p>

    </div>
</section>

