import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuLink, UiService } from '../providers/ui.service';
import { BaseComponent } from '@core/abstracts/base.component';
import { MatSidenav } from '@angular/material/sidenav';
import { Identity, TokenService } from '@core/services/token.service';
import { AuthService } from '@core/services/auth.service';

@Component({
    selector: 'ddf-menu',
    templateUrl: './menu.html',
    standalone: false
})
export class MenuComponent extends BaseComponent
{
    public identity: Identity | undefined;
    public menuLinks: MenuLink[];
    @Input()
    public sidenav: MatSidenav | undefined;

    constructor(
        public tokenService: TokenService,
        public authService: AuthService,
        public uiService: UiService,
        private router: Router,
    )
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        this.identity = this.tokenService.getIdentity();
        this.menuLinks = this.uiService.getMenuLinks()
            .filter(x => !x.title.includes('Profile'));
    }

    public selectMenu(ev: Event, route: string[]): void
    {
        this.toggleSidenav();
    }

    public toggleSidenav(): void
    {
        this.sidenav.toggle();
    }

    public async signoutAsync(): Promise<void>
    {
        await this.router.navigateByUrl('/auth/signin');
        await this.authService.signoutAsync();

        this.uiService.removeFilterSettingsFromLocalStorage();
    }
}
