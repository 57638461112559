import { Component } from '@angular/core';

@Component({
    selector: 'ddf-portal-app',
    template: `<div class="layout-fill">
        <router-outlet></router-outlet>
    </div>`,
    standalone: false
})
export class AppComponent
{

}
