import { Component } from '@angular/core';
import { BaseComponent } from '@core/abstracts/base.component';

@Component({
    templateUrl: './not-found.html',
    standalone: false
})
export class NotFoundComponent extends BaseComponent
{

}
