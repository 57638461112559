<div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-white">

    <button mat-icon-button (click)="toggleSidenav()">
        <mat-icon>close</mat-icon>
    </button>

    <a routerLink="/">
        <img src="assets/images/ddfinance-logo.png" alt="DDFinance" class="tw-max-h-[24px]" />
    </a>

</div>

<mat-divider></mat-divider>

<mat-nav-list>
    @for (menuLink of menuLinks; track menuLink) {
    <a mat-list-item [routerLink]="menuLink.link" routerLinkActive="is-active"
        (click)="selectMenu($event, menuLink.link)" data-test="simple">
        <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-text-brand-default">
            <mat-icon [svgIcon]="menuLink.icon"></mat-icon>
            <h1 class="tw-uppercase tw-text-lg" [attr.data-test]="menuLink.title">{{ menuLink.title }}</h1>
        </div>
    </a>
    }
</mat-nav-list>

<mat-divider></mat-divider>


<button class="tw-m-2" type="button" mat-fab extended aria-label="Log Out" (click)="signoutAsync()">
    <mat-icon svgIcon="login"></mat-icon>
    Logout of DDFinance
</button>