<header *ngIf="identity">
    <div class="ph-10">
        <h2 class="tw-uppercase">
            welcome, {{identity.name}}
        </h2>
    </div>
</header>

<section class="ph-10" *ngIf="menuLinks">

    <div class="layout-row layout-align-start-stretch layout-wrap">

        <div class="flex-20 flex-xs-100 flex-sm-50 flex-md-33 p-10" *ngFor="let item of menuLinks">

            <div class="card text-center cursor-pointer" [routerLink]="item.link">

                <div class="card-head">
                    <h3 class="card-title" title="{{ item.title  }}">
                        <mat-icon [svgIcon]="item.icon" title="{{ item.title  }}" aria-hidden="true" style="transform: scale(1.7);"></mat-icon>
                        <p title="{{ item.title  }}" class="pt-5">{{ item.title  }}</p>
                    </h3>
                </div>

                <div class="card-body pb-10">
                    {{ item.description  }}
                </div>

            </div>

        </div>

    </div>

</section>
