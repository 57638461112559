export function getIcons(): string[]
{
    return [
        'account',
        'account-arrow-right',
        'account-box',
        'account-box-outline',
        'account-edit',
        'account-group',
        'account-group-outline',
        'account-credit-card',
        'account-lock',
        'account-key',
        'account-multiple',
        'account-multiple-check',
        'account-plus',
        'account-search',
        'account-star',
        'alert',
        'alert-circle',
        'alert-circle-outline',
        'attachment',
        'basket',
        'bookmark-check',
        'calendar-range',
        'cart-plus',
        'cash',
        'cash-multiple',
        'cash-refund',
        'cellphone',
        'cellphone-text',
        'cellphone-text',
        'cellphone-wireless',
        'chart-bar',
        'chart-bell-curve-cumulative',
        'check',
        'checkbox-blank-circle-outline',
        'checkbox-marked-circle-outline',
        'chevron-down',
        'chevron-left',
        'chevron-left',
        'chevron-right',
        'chevron-up',
        'circle-edit-outline',
        'close',
        'cloud-download-outline',
        'cloud-upload-outline',
        'code-tags',
        'content-copy',
        'create',
        'credit-card',
        'delete-sweep',
        'domain',
        'dots-vertical',
        'drag',
        'export-variant',
        'eye',
        'file-document-edit-outline',
        'file-table-box-outline',
        'finance',
        'format-list-checks',
        'forwardburger',
        'gender-male-female',
        'information',
        'key',
        'lan-connect',
        'lan-disconnect',
        'link',
        'loading',
        'lock-alert',
        'lock-open',
        'lock-reset',
        'login',
        'logout',
        'magnify',
        'map-marker',
        'menu',
        'message',
        'message-outline',
        'office-building',
        'open-in-new',
        'page-layout-sidebar-left',
        'pencil',
        'pencil-outline',
        'phone',
        'play',
        'playlist-star',
        'plus',
        'receipt',
        'reload',
        'send',
        'send-outline',
        'share-variant',
        'sort',
        'star',
        'star-outline',
        'table-of-contents',
        'text',
        'thought-bubble-outline',
        'tune',
        'tune-vertical',
        'unfold-less-vertical',
        'unfold-more-vertical',
        'view-dashboard',
        'view-dashboard-outline',
        'wallet-membership',
        'wifi-strength-alert-outline'
      ];
}
