@if(identity) {
<header>
    <h2 class="tw-uppercase tw-px-4">
        {{identity.name}}
    </h2>
</header>
}

@if(menuLinks) {
<section>
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            @for(menuLink of menuLinks; track menuLink) {
            <div class="tw-card tw-flex-col tw-m-4 tw-p-8 tw-text-center tw-cursor-pointer"
                [routerLink]="menuLink.link">

                <h3 class="tw-text-lg tw-uppercase">
                    <mat-icon [svgIcon]="menuLink.icon" style="transform: scale(1.7);"></mat-icon>
                    <p>{{ menuLink.title }}</p>
                </h3>
                <p>{{ menuLink.description }}</p>
            </div>
            }
        </div>
</section>
}