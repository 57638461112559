import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreakpointService
{
    static watchList = [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
    ];

    constructor(
        private breakpointObserver: BreakpointObserver,
    ) { }

    public isMatch$(breakpoint: string): Observable<boolean>
    {
        return this.current$.pipe(
            map(current => !!breakpoint && current === breakpoint.toLowerCase())
        );
    }

    public get current$(): Observable<string | null>
    {
        return this.breakpointObserver.observe(
            BreakpointService.watchList
        )
            .pipe(
                map(_ =>
                {
                    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) return 'xs';
                    if (this.breakpointObserver.isMatched(Breakpoints.Small)) return 'sm';
                    if (this.breakpointObserver.isMatched(Breakpoints.Medium)) return 'md';
                    if (this.breakpointObserver.isMatched(Breakpoints.Large)) return 'lg';
                    if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) return 'xl';
                    return null;
                })
            )
    }
}
