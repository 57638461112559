<div class="pv-10 ph-15 tw-flex tw-flex-row tw-justify-between">

    <a routerLink="/">
        <img src="assets/ddfinance-logo.png" alt="DDFinance" style="max-height: 35px;" />
    </a>
    <button mat-icon-button (click)="sidenav.toggle()" *ngIf="isMobile ">
        <mat-icon [svgIcon]="isOpen ? 'close' : 'close'" aria-label="Menu"></mat-icon>
    </button>

</div>


<mat-divider></mat-divider>

<mat-nav-list>

    <a mat-list-item [routerLink]="l.link" routerLinkActive="is-active" *ngFor="let l of menuLinks"
        (click)="selectMenu($event, l.link)" data-test="simple">
        <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-text-brand-default">
            <mat-icon [svgIcon]="l.icon"></mat-icon>
            <span class="tw-uppercase" [attr.data-test]="l.title">{{ l.title }}</span>
        </div>
    </a>

</mat-nav-list>

<mat-divider></mat-divider>

<div class="layout-row layout-align-space-between-center p-10">

    <button type="button" mat-fab extended aria-label="Log Out" (click)="signoutAsync()">
        <mat-icon svgIcon="login"></mat-icon>
        Logout of DDFinance
    </button>
</div>