import { MenuLink, UiService } from '@/core/providers/ui.service';
import { Component } from '@angular/core';
import { BaseComponent } from '@core/abstracts/base.component';
import { Identity, TokenService } from '@core/services/token.service';


@Component({
    templateUrl: './dashboard.component.html',
    styles: [],
    standalone: false
})
export class DashboardComponent extends BaseComponent
{

    public identity: Identity | undefined;
    public menuLinks: MenuLink[] | undefined;

    constructor(
        private uiService: UiService,
        private tokenService: TokenService)
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();

        this.uiService.removeFilterSettingsFromLocalStorage();
        this.identity = this.tokenService.getIdentity();
        this.menuLinks = this.uiService.getMenuLinks();
    }
}
